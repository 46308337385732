
import { Giftshop, GiftshopCode, GiftshopCodeInvitationState, GiftshopManagementModal, GiftshopManagementSection, GiftshopManagementSubsection, GiftshopStatus } from '@/models/Giftshop';
import GiftshopManagementModule from '@/store/modules/GiftshopManagementModule';
import UserModule from '@/store/modules/UserModule';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'GiftshopManagementShortcuts',
    components: {
    },
})
export default class GiftshopManagementShortcuts extends Vue {
    
    get isSupervisor(): boolean {
        return UserModule.USER_ROLE == "Supervisor";
    }
    get isSalesperson(): boolean {
        return UserModule.USER_ROLE == "Salesperson";
    }

    get giftshop(): Giftshop {
        return GiftshopManagementModule.CURRENT_GIFTSHOP;
    }
    get isClosed(): boolean {
        return this.giftshop?.status == GiftshopStatus.CLOSED;
    }

    get section(): GiftshopManagementSection {
        return GiftshopManagementModule.SECTION;
    }
    get subsection(): GiftshopManagementSubsection {
        return GiftshopManagementModule.SUBSECTION;
    }
    get isDashboard(): boolean {
        return this.section == GiftshopManagementSection.DASHBOARD;
    }
    get isOverview(): boolean {
        return this.section == GiftshopManagementSection.OVERVIEW;
    }
    get isAssortment(): boolean {
        return this.section == GiftshopManagementSection.ASSORTMENT;
    }
    get isCodes(): boolean {
        return this.section == GiftshopManagementSection.CODES;
    }
    get isSupervisorGiftshops(): boolean {
        return this.section == GiftshopManagementSection.SUPERVISOR_GIFTSHOPS;
    }
    get isSupervisorProducts(): boolean {
        return this.section == GiftshopManagementSection.SUPERVISOR_PRODUCTS;
    }

    get codes(): GiftshopCode[] {
        return GiftshopManagementModule.CURRENT_GIFTSHOP_CODES;
    }

    get items(): any[] {
        const items: any[] = [];

        if (this.isDashboard || this.isSupervisorGiftshops || this.isSupervisorProducts)
        {
            if (this.isSupervisor || this.isSalesperson)
            {
                items.push({ name: "Gaveshop oversigt", section: GiftshopManagementSection.SUPERVISOR_GIFTSHOPS, icon: "list" });
                
                if (this.isSupervisor)
                {
                    items.push({ name: "Disponering", section: GiftshopManagementSection.SUPERVISOR_PRODUCTS, icon: "bag" });
                }

                items.push({ divider: true });
            }
            
            items.push({ name: "Opret gaveshop", modal: GiftshopManagementModal.CREATE_GIFTSHOP, icon: "plus", primary: true });
        }
        else if (this.giftshop)
        {
            items.push({ name: "Overblik", section: GiftshopManagementSection.OVERVIEW, icon: "fa-arrow-circle-right", disabled: this.isOverview });
            items.push({ name: "Sortiment", section: GiftshopManagementSection.ASSORTMENT, icon: "fa-arrow-circle-right", disabled: this.isAssortment });
            items.push({ name: "Gavebeviser", section: GiftshopManagementSection.CODES, icon: "fa-arrow-circle-right", disabled: this.isCodes });

            if (!this.isClosed)
            {
                items.push({ divider: true });

                if (this.isOverview)
                {
                    items.push({ name: "Ret dato", modal: GiftshopManagementModal.EDIT_DATES, icon: "fal-pencil", primary: true });
                    items.push({ name: "Ret leveringsuge", modal: GiftshopManagementModal.EDIT_DELIVERY_DATE, icon: "fal-pencil", primary: true });

                    if (!this.giftshop.homedeliveryrequested)
                        items.push({ name: "Ret leveringsadresse", modal: GiftshopManagementModal.EDIT_DELIVERY_ADDRESS, icon: "fal-pencil", primary: true });
                    
                    items.push({ name: "Ret tema", modal: GiftshopManagementModal.EDIT_THEME, icon: "fal-pencil", primary: true });
                    items.push({ name: "Ret hilsen", modal: GiftshopManagementModal.EDIT_MESSAGE, icon: "fal-pencil", primary: true });
                }
                else if (this.isAssortment)
                {
                    if (this.subsection == GiftshopManagementSubsection.NONE)
                    {
                        items.push({ name: "Vælg standardgaver", subsection: GiftshopManagementSubsection.ASSORTMENT_DEFAULT_PRODUCTS, icon: "fal-pencil", primary: true });
                        items.push({ name: "Ret sortiment", subsection: GiftshopManagementSubsection.ASSORTMENT_PRODUCTS, icon: "fal-pencil", primary: true });
                    }
                    else
                    {
                        items.push({ name: "Afslut", subsection: GiftshopManagementSubsection.NONE, icon: "fal-check", primary: true });

                        if (this.codes.length == 0 && this.giftshop.defaultproducts.length == this.giftshop.requireddefaultproducts && this.subsection == GiftshopManagementSubsection.ASSORTMENT_DEFAULT_PRODUCTS)
                            items.push({ name: "Afslut og gå til gavebeviser", section: GiftshopManagementSection.CODES, icon: "fal-check", primary: true });
                        else if (this.giftshop.defaultproducts.length < this.giftshop.requireddefaultproducts && this.giftshop.products.length >= this.giftshop.requireddefaultproducts && this.subsection == GiftshopManagementSubsection.ASSORTMENT_PRODUCTS)
                            items.push({ name: "Afslut og vælg standardgaver", subsection: GiftshopManagementSubsection.ASSORTMENT_DEFAULT_PRODUCTS, icon: "fal-check", primary: true });
                    }
                }
                else if (this.isCodes)
                {
                    items.push({ name: this.codes?.length > 0 ? "Opret flere gavebeviser" : "Opret de første gavebeviser", modal: GiftshopManagementModal.ADD_CODES, icon: "fal-plus", primary: true });
                    
                    if (this.codes.length > 0)
                    {
                        if (!this.codes.some(code => code.invitationstate != GiftshopCodeInvitationState.MANUAL) && !this.giftshop.cardsrequested)
                            items.push({ name: "Vælg leveringsform", modal: GiftshopManagementModal.SEND_CODES, icon: "fal-pencil", primary: true });
                        else
                            items.push({ name: "Send gavebeviser", modal: GiftshopManagementModal.SEND_CODES, icon: "fal-envelope", primary: true });
                    }

                    items.push({ name: "Ret tema", modal: GiftshopManagementModal.EDIT_THEME, icon: "fal-pencil", primary: true });
                    items.push({ name: "Ret hilsen", modal: GiftshopManagementModal.EDIT_MESSAGE, icon: "fal-pencil", primary: true });
                }
            }
        }
        return items;
    }

    goto(item: any)
    {
        if (item.section != null)
            GiftshopManagementModule.CHANGE_SECTION(item.section);
        else if (item.subsection != null)
            GiftshopManagementModule.SET_SUBSECTION(item.subsection);
        else if (item.modal)
            GiftshopManagementModule.SET_ACTIVE_MODAL(item.modal);
    }
}
