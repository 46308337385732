
import { Component, Vue, Prop } from "vue-property-decorator";
import { SignUpRequest, SignUpResponseError } from "@/models/SignUp";
import SignUpFormTextInput from "./SignUpFormTextInput.vue";
import SignUpFormAddressInputSuggest from "./SignUpFormAddressInputSuggest.vue";
import CustomerService from "@/services/CustomerService";

const service: CustomerService = new CustomerService();

@Component({
    name: "SignUpForm",
    components: {
        SignUpFormTextInput,
        SignUpFormAddressInputSuggest,
    }
})

export default class SignUpForm extends Vue {
    @Prop({ type: String, required: false, default: "#" }) termsPage: string;
    @Prop({ type: String, required: false, default: "" }) title: string;
    @Prop({ type: String, required: false, default: "" }) description: string;

    consent: boolean = false;
    completed: boolean = false;
    error: SignUpResponseError = SignUpResponseError.None;

    model: SignUpRequest = null;

    get translationsReady(): boolean {
        return this.$root.$data.translationsLoaded === true;
    }

    get showHeader(): boolean {
        return this.title.length > 0 || this.description.length > 0;
    }

    get isError() {
        return this.error !== SignUpResponseError.None;
    }

    get eanRule(): boolean {
        if (this.model.eanno != null && this.model.eanno != "") {
            return true;
        }
        else {
            return false;
        }
    }

    get errorMessage(): string {
        if (this.error === SignUpResponseError.MissingDetails)
            return String(this.$t("signup.error-missing-details", ["Please provide the nessesary details."]));
        else if (this.error === SignUpResponseError.CustomerExists)
            return String(this.$t("signup.error-customer-exists", ["A customer already exist with provided details."]));
        else if (this.error === SignUpResponseError.UserExists)
            return String(this.$t("signup.error-user-exists", ["A user already exist with provided email address."]));

        return String(this.$t("signup.error-message", ["The system encountered an error, please contact our support."]));
    }

    reset() {
        this.completed = false;
        this.error = SignUpResponseError.None;
    }

    async send() {
        // sanitize phone
        this.model.phone = this.model.phone.replaceAll(' ', '');

        const res = await service.signUp(this.model);

        if (res.signupcompleted === true)
            this.completed = true;
        else
            this.error = res.error;
    }

    handleSuggest(input: any) {
        if (input.suggestion) {
            this.model.address = input.suggestion;
        } else {
            this.model.address = input.address;
            this.model.zipcode = input.zipcode;
            this.model.city = input.city;
        }
    }

    private created() {
        this.model = {
            name: null,
            email: null,
        };
    }
}
