

import { Giftshop, GiftshopCode, GiftshopProductInfo } from '@/models/Giftshop';
import GiftshopFrontModule from '@/store/modules/GiftshopFrontModule';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'GiftshopFrontCompleted',
    components: {

    },
})
export default class GiftshopFrontCompleted extends Vue {

    get giftshop(): Giftshop {
        return GiftshopFrontModule.GIFTSHOP;
    }

    get code(): GiftshopCode {
        return GiftshopFrontModule.CODE;
    }

    get products(): GiftshopProductInfo[] {
        return this.code.productsinfo;
    }

    handleLeave() {
        GiftshopFrontModule.LEAVE();
    }
}
