
const UIkit = (window as any).UIkit;
import { CustomIndexConfigFieldType, FilterModel } from '@/models/Filter';
import { Giftshop, GiftshopCode, GiftshopCodeEditModel, GiftshopFrontSection, GiftshopProductInfo, GiftshopProductViewModel } from '@/models/Giftshop';
import GiftshopFrontModule from '@/store/modules/GiftshopFrontModule';
import { copy } from '@/utils/objectUtils';
import { Component, Vue } from 'vue-property-decorator';
import GiftshopProductInformation from '../giftshop-utils/GiftshopProductInformation.vue';

@Component({
    name: 'GiftshopFrontProducts',
    components: {
        GiftshopProductInformation,
    },
})
export default class GiftshopFrontProducts extends Vue {
    
    get isLoading(): boolean {
        return GiftshopFrontModule.LOADING;
    }

    get giftshop(): Giftshop {
        return GiftshopFrontModule.GIFTSHOP;
    }

    get code(): GiftshopCode {
        return GiftshopFrontModule.CODE;
    }

    get viewProduct(): GiftshopProductViewModel {
        return GiftshopFrontModule.VIEW_PRODUCT;
    }
    resetViewProduct() {
        GiftshopFrontModule.SET_VIEW_PRODUCT(null);
    }

    get productSelection(): GiftshopProductInfo[] {
        return GiftshopFrontModule.PRODUCT_SELECTION;
    }
    toggleProductSelection(product: GiftshopProductInfo) {
        let editmodel: GiftshopProductInfo[] = copy(this.productSelection);

        if (editmodel.find(p => p.id == product.id))
            editmodel = editmodel.filter(p => p.id != product.id);
        else
            editmodel.push(product);

        GiftshopFrontModule.SET_PRODUCT_SELECTION(editmodel);
    }

    get selections(): GiftshopProductInfo[] {
        let selections = copy(this.productSelection);
        for (let i = this.productSelection.length; i < this.giftshop.requireddefaultproducts; i++)
        {
            selections.push(null);
        }

        return selections;
    }

    get query(): FilterModel {
        if (this.giftshop?.products.length > 0)
        {
            return {
                count: 20,
                offset: 0,
                preselections: [
                    {
                        path: "ID",
                        values: this.giftshop.products
                    }
                ],
                fields: [
                    {
                        path: 'data.attributedata.type_spec',
                        type: CustomIndexConfigFieldType.STRING
                    },
                    {
                        path: 'data.attributedata.brand',
                        type: CustomIndexConfigFieldType.STRING
                    }
                ],
                sortoptions: null,
                restrictdoctypes: [ "PRODUCT" ],
                usefuzzymatch: false,
            };
        }

        return null;
    }

    handleContinue() {
        UIkit.modal.confirm("Ønsker du at afslutte dit gavevalg?", {labels: {ok: "Ja", cancel: "Nej"}}).then(async () => {
            let editModel = new GiftshopCodeEditModel(this.code);
            editModel.products = this.productSelection.map(p => p.id);
            editModel.productsinfo = copy(this.productSelection);

            GiftshopFrontModule.SET_LOADING(true);
            
            const code = await GiftshopFrontModule.PERSIST_CODE(editModel);
            if (code)
                GiftshopFrontModule.SET_CURRENT_SECTION(GiftshopFrontSection.COMPLETED);

            GiftshopFrontModule.SET_LOADING(false);
        });
    }
}
