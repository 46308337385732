
import { Giftshop, GiftshopManagementSection, GiftshopManagementSubsection } from '@/models/Giftshop';
import GiftshopManagementModule from '@/store/modules/GiftshopManagementModule';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'GiftshopManagementBreadcrumb',
    components: {
    },
})
export default class GiftshopManagementBreadcrumb extends Vue {
    
    displayGiftshopSelector: boolean = false;

    get giftshops(): Giftshop[] {
        return GiftshopManagementModule.GIFTSHOPS;
    }

    get giftshop(): Giftshop {
        return GiftshopManagementModule.CURRENT_GIFTSHOP;
    }

    get section(): GiftshopManagementSection {
        return GiftshopManagementModule.SECTION;
    }
    get subSection(): GiftshopManagementSubsection {
        return GiftshopManagementModule.SUBSECTION;
    }
    get isDashboard(): boolean {
        return this.section == GiftshopManagementSection.DASHBOARD;
    }
    get isOverview(): boolean {
        return this.section == GiftshopManagementSection.OVERVIEW;
    }
    get isAssortment(): boolean {
        return this.section == GiftshopManagementSection.ASSORTMENT;
    }
    get isCodes(): boolean {
        return this.section == GiftshopManagementSection.CODES;
    }
    get isSupervisorGiftshops(): boolean {
        return this.section == GiftshopManagementSection.SUPERVISOR_GIFTSHOPS;
    }
    get isSupervisorProducts(): boolean {
        return this.section == GiftshopManagementSection.SUPERVISOR_PRODUCTS;
    }

    get items(): any[] {
        const items: any[] = [];

        items.push({ name: "Oversigt", section: GiftshopManagementSection.DASHBOARD, home: true });
        if (this.giftshop)
        {
            items.push({ name: this.giftshop.name, section: GiftshopManagementSection.OVERVIEW, giftshop: true });
            if (this.isOverview)
                items.push({ name: "Overblik" });
            else if (this.isAssortment)
            {
                if (this.subSection == GiftshopManagementSubsection.NONE)
                    items.push({ name: "Sortiment" });
                else {
                    items.push({ name: "Sortiment", section: GiftshopManagementSection.ASSORTMENT });
                    
                    if (this.subSection == GiftshopManagementSubsection.ASSORTMENT_DEFAULT_PRODUCTS)
                        items.push({ name: "Vælg standardgaver" }); 
                    else if (this.subSection == GiftshopManagementSubsection.ASSORTMENT_PRODUCTS)
                        items.push({ name: "Ret sortiment" }); 
                }
            }
            else if (this.isCodes)
                items.push({ name: "Gavebeviser" });            
        }
        else if (this.isSupervisorGiftshops)
            items.push({ name: "Gaveshop oversigt" });
        else if (this.isSupervisorProducts)
            items.push({ name: "Disponering" });

        return items;
    }

    goto(section: GiftshopManagementSection) {
        GiftshopManagementModule.CHANGE_SECTION(section);
    } 
}
