
import { Component, Vue, Prop } from 'vue-property-decorator';
import { toCssClassName } from '../../utils/StringUtils';
import { GiftshopProductViewModel } from '@/models/Giftshop';

@Component({
    name: 'GiftshopProductInformation',
})
export default class GiftshopProductInformation extends Vue {
    @Prop({ type: Object, required: true }) product: GiftshopProductViewModel;

    get translationsReady(): boolean {
        return this.$root.$data.translationsLoaded === true;
    }

    get extraImages(): string[] {
        return this.product?.images.slice(1);
    }

    getCssClassName(prefix: string, input: string) {
        return toCssClassName(prefix, input);
    }
}
