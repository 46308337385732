import VueRouter, { RouteConfig } from "vue-router";
import Vue from "vue";
import GiftshopManagementModule from "@/store/modules/GiftshopManagementModule";
import { GiftshopFrontSection, GiftshopManagementSection } from "@/models/Giftshop";
import GiftshopFrontModule from "@/store/modules/GiftshopFrontModule";

Vue.use(VueRouter);


const ensureManagementData = async (params: any, section: GiftshopManagementSection = null): Promise<void> => {

    if (!GiftshopManagementModule.SETTINGS)
        await GiftshopManagementModule.GET_SETTINGS();

    if (!GiftshopManagementModule.ADDRESSES && section == GiftshopManagementSection.OVERVIEW)
        await GiftshopManagementModule.GET_ADDRESSES();

    if (GiftshopManagementModule.GIFTSHOPS.length == 0)
        await GiftshopManagementModule.GET_GIFTSHOPS();

    if (params?.giftshop && GiftshopManagementModule.CURRENT_GIFTSHOP_ID != params.giftshop)
        await GiftshopManagementModule.SET_CURRENT_GIFTSHOP(params.giftshop);

    if (section == GiftshopManagementSection.OVERVIEW || section == GiftshopManagementSection.CODES)
        await GiftshopManagementModule.GET_GIFTSHOP_CODES();

    if (GiftshopManagementModule.CURRENT_GIFTSHOP && !params.giftshop)
        GiftshopManagementModule.SET_CURRENT_GIFTSHOP(null);
};

const ensureFrontData = async (params: any): Promise<void> => {        
    
    if (params.code)
    {
        GiftshopFrontModule.SET_LOADING(true);

        const code = await GiftshopFrontModule.GET_CODE(params.code);
        if (code)
            await GiftshopFrontModule.GET_GIFTSHOP(code.shopid);

        GiftshopFrontModule.SET_LOADING(false);

        GiftshopFrontModule.SET_CURRENT_SECTION(GiftshopFrontSection.INFO);
    }

    if (!GiftshopFrontModule.GIFTSHOP && params.giftshop)
    {
        GiftshopFrontModule.SET_LOADING(true);

        await GiftshopFrontModule.GET_GIFTSHOP(params.giftshop);

        GiftshopFrontModule.SET_LOADING(false);
    }
};

const routeHome = `/${(window as any).giftshopAppHome}`;
const routes: Array<RouteConfig> = [
    {
        path: "/",
        redirect: routeHome,
    },
    {
        path: "/m",
        name: "GiftshopManagementDashboard",
        component: () => import(/* webpackChunkName: "giftshop-management-dashboard" */ "../components/giftshop-management/GiftshopManagementDashboard.vue"),
        beforeEnter: async (to: any, _: any, next: any) => {
            await ensureManagementData(to.params, GiftshopManagementSection.DASHBOARD);
            await GiftshopManagementModule.SET_SECTION(GiftshopManagementSection.DASHBOARD);
            next();
        },
    },
    {
        path: "/m/giftshops",
        name: "GiftshopManagementSupervisorGiftshops",
        component: () => import(/* webpackChunkName: "giftshop-management-supervisor-giftshops" */ "../components/giftshop-management/GiftshopManagementSupervisorGiftshops.vue"),
        beforeEnter: async (to: any, _: any, next: any) => {
            await ensureManagementData(to.params, GiftshopManagementSection.SUPERVISOR_GIFTSHOPS);
            await GiftshopManagementModule.SET_SECTION(GiftshopManagementSection.SUPERVISOR_GIFTSHOPS);
            next();
        },
    },
    {
        path: "/m/products",
        name: "GiftshopManagementSupervisorProducts",
        component: () => import(/* webpackChunkName: "giftshop-management-supervisor-products" */ "../components/giftshop-management/GiftshopManagementSupervisorProducts.vue"),
        beforeEnter: async (to: any, _: any, next: any) => {
            await ensureManagementData(to.params, GiftshopManagementSection.SUPERVISOR_PRODUCTS);
            await GiftshopManagementModule.SET_SECTION(GiftshopManagementSection.SUPERVISOR_PRODUCTS);
            next();
        },
    },
    {
        path: "/m/:giftshop",
        name: "GiftshopManagementOverview",
        component: () => import(/* webpackChunkName: "giftshop-management-overview" */ "../components/giftshop-management/GiftshopManagementOverview.vue"),
        beforeEnter: async (to: any, _: any, next: any) => {
            await ensureManagementData(to.params, GiftshopManagementSection.OVERVIEW);
            await GiftshopManagementModule.SET_SECTION(GiftshopManagementSection.OVERVIEW);
            next();
        },
    },
    {
        path: "/m/:giftshop/ass",
        name: "GiftshopManagementAssortment",
        component: () => import(/* webpackChunkName: "giftshop-management-assortment" */ "../components/giftshop-management/GiftshopManagementAssortment.vue"),
        beforeEnter: async (to: any, _: any, next: any) => {
            await ensureManagementData(to.params, GiftshopManagementSection.ASSORTMENT);
            await GiftshopManagementModule.SET_SECTION(GiftshopManagementSection.ASSORTMENT);
            next();
        },
    },
    {
        path: "/m/:giftshop/inv",
        name: "GiftshopManagementCodes",
        component: () => import(/* webpackChunkName: "giftshop-management-codes" */ "../components/giftshop-management/GiftshopManagementCodes.vue"),
        beforeEnter: async (to: any, _: any, next: any) => {
            await ensureManagementData(to.params, GiftshopManagementSection.CODES);
            await GiftshopManagementModule.SET_SECTION(GiftshopManagementSection.CODES);
            next();
        },
    },
    {
        path: "/g/:giftshop?/:code?",
        name: "GiftshopFrontLogin",
        component: () => import(/* webpackChunkName: "giftshop-front-login" */ "../components/giftshop/GiftshopFrontLogin.vue"),
        beforeEnter: async (to: any, _: any, next: any) => {
            ensureFrontData(to.params);
            next();
        },
    },
];

// to ensure that history doesn't get changed on non-giftshop pages we only create router in giftshop pages
let router: VueRouter = null;
if ((window as any).CbxIsGiftshopPage)
{
    router = new VueRouter({
        routes: [...routes],
    });

    router.beforeEach((to: any, from: any, next: any) => {
        if (to.name != null && from.name == to.name)
        {
            if (to.name.startsWith("GiftshopManagement"))
            {
                let section = GiftshopManagementSection.DASHBOARD;
                if (to.name == "GiftshopManagementOverview") section = GiftshopManagementSection.OVERVIEW;
                else if (to.name == "GiftshopManagementAssortment") section = GiftshopManagementSection.ASSORTMENT;
                else if (to.name == "GiftshopManagementCodes") section = GiftshopManagementSection.CODES;
        
                ensureManagementData(to.params, section);
            }
            else
            {
                //
            }
        }
        
        next();
    });
}

export default router;