import { Module, VuexModule, Mutation, getModule, Action } from 'vuex-module-decorators';
import store from '@/store';
import router from '@/router';
import { Giftshop, GiftshopCode, GiftshopCodeEditModel, GiftshopCodeState, GiftshopFrontSection, GiftshopProductInfo, GiftshopState, GiftshopStatus, GiftshopProductViewModel } from '@/models/Giftshop';
import GiftshopService from '@/services/GiftshopService';
import { toUTCDate, utcToday } from '@/utils/DateUtils';

@Module({
    store,
    dynamic: true,
    name: 'giftshop-front',
    namespaced: true,
})
class GiftshopFrontModule extends VuexModule {

    private service: GiftshopService = new GiftshopService();
    private routeHome: string = (window as any).giftshopAppHome;

    private currentSection: GiftshopFrontSection = GiftshopFrontSection.LOGIN;
    private loading: boolean = false;
    private code: GiftshopCode = null;
    private codeState: GiftshopCodeState = null;
    private giftshop: Giftshop = null;
    private giftshopState: GiftshopState = null;
    private productSelection: GiftshopProductInfo[] = [];
    private viewProduct: GiftshopProductViewModel = null;

    get CURRENT_SECTION(): GiftshopFrontSection {
        return this.currentSection;
    }

    get LOADING(): boolean {
        return this.loading;
    }

    get CODE(): GiftshopCode {
        return this.code;
    }

    get CODE_STATE(): GiftshopCodeState {
        return this.codeState;
    }

    get GIFTSHOP(): Giftshop {
        return this.giftshop;
    }

    get GIFTSHOP_STATE(): GiftshopState {
        return this.giftshopState;
    }

    get PRODUCT_SELECTION(): GiftshopProductInfo [] {
        return this.productSelection;
    }

    get VIEW_PRODUCT(): GiftshopProductViewModel {
        return this.viewProduct;
    }

    @Mutation
    public SET_CURRENT_SECTION(args: GiftshopFrontSection) {
        this.currentSection = args;
    }

    @Mutation
    public SET_LOADING(args: boolean) {
        this.loading = args;
    }

    @Action({ commit: 'SET_CODE' })
    public async GET_CODE(args: string) {
        return await this.service.getGiftshopCode(args);
    }

    @Action({ commit: 'SET_CODE' })
    public async PERSIST_CODE(args: GiftshopCodeEditModel) {
        return await this.service.persistGiftshopCode(args);
    }

    @Mutation
    public SET_CODE(payload: GiftshopCode) {
        this.code = payload;

        if (this.code == null) {
            this.codeState = GiftshopCodeState.UNKNOWN;
            return;
        } 
        else if (this.code.products.length > 0) {
            this.codeState = GiftshopCodeState.USED;
            this.productSelection = this.code.productsinfo;
        }
        else this.codeState = GiftshopCodeState.UNUSED;

        if (router.currentRoute.params.giftshop != this.code.shopid)
            router.push(`/${this.routeHome}/${this.code.shopid}/${this.code.id}`);
    }

    @Action({ commit: 'SET_GIFTSHOP' })
    public async GET_GIFTSHOP(args: string) {
        return await this.service.getGiftshop(args);
    }

    @Mutation
    public SET_GIFTSHOP(payload: Giftshop) {
        this.giftshop = payload;

        const today = utcToday();

        if (this.giftshop == null) this.giftshopState = GiftshopState.UNKNOWN;
        else if (this.giftshop.status == GiftshopStatus.CLOSED) this.giftshopState = GiftshopState.CLOSED;
        else if (toUTCDate(this.giftshop.enddate) < today) this.giftshopState = GiftshopState.CLOSED;
        else if (toUTCDate(this.giftshop.startdate) > today) this.giftshopState = GiftshopState.AWAITING;
        else this.giftshopState = GiftshopState.OPEN;
    }

    @Mutation
    public SET_PRODUCT_SELECTION(payload: GiftshopProductInfo[]) {
        this.productSelection = payload;
    }

    @Mutation
    public FINALIZE() {
        this.productSelection = [];
    }

    @Mutation
    public LEAVE() {
        this.code = null;
        this.codeState = null;
        this.currentSection = GiftshopFrontSection.LOGIN;      
        
        router.push(`/${this.routeHome}/${this.giftshop.id}`);
    }

    @Action({ commit: 'SET_VIEW_PRODUCT' })
    public async GET_VIEW_PRODUCT(id: string) {
        return (await this.service.getProductInfo([id]))?.[0];
    }

    @Mutation
    public SET_VIEW_PRODUCT(payload: GiftshopProductViewModel) {
        this.viewProduct = payload;
    }
}

export default getModule(GiftshopFrontModule);
