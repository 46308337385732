import { Module, VuexModule, Mutation, getModule, Action } from 'vuex-module-decorators';
import store from '@/store';
import router from '@/router';
import { Giftshop, GiftshopCode, GiftshopCodeInvitationSentStatus, GiftshopCodeInvitationState, GiftshopEditModel, GiftshopInvitation, GiftshopManagementModal, GiftshopManagementSection, GiftshopManagementSubsection, GiftshopProductViewModel, GiftshopSettings, GiftshopUser } from '@/models/Giftshop';
import GiftshopService from '@/services/GiftshopService';
import { AddressInfoDTO } from '@/models/Checkout';

@Module({
    store,
    dynamic: true,
    name: 'giftshop-management',
    namespaced: true,
})
class GiftshopManagementModule extends VuexModule {

    private service: GiftshopService = new GiftshopService();
    private routeHome: string = (window as any).giftshopAppHome;

    private settings: GiftshopSettings = null;
    private addresses: AddressInfoDTO[] = null;
    private section: GiftshopManagementSection = GiftshopManagementSection.DASHBOARD;
    private subsection: GiftshopManagementSubsection = GiftshopManagementSubsection.NONE;
    private activeModal: GiftshopManagementModal = GiftshopManagementModal.NONE;
    private giftshops: Giftshop[] = [];
    private currentGiftshopId: string = null;
    private currentGiftshop: Giftshop = null;
    private currentGiftshopCodes: GiftshopCode[] = [];
    private products: GiftshopProductViewModel[] = [];
    private viewProduct: GiftshopProductViewModel = null;
    private salespersonUsers: GiftshopUser[] = [];

    get SETTINGS(): GiftshopSettings {
        return this.settings;
    }

    get ADDRESSES(): AddressInfoDTO[] {
        return this.addresses;
    }

    get SECTION(): GiftshopManagementSection {
        return this.section;
    }

    get SUBSECTION(): GiftshopManagementSubsection {
        return this.subsection;
    }

    get ACTIVE_MODAL(): GiftshopManagementModal {
        return this.activeModal;
    }

    get GIFTSHOPS(): Giftshop[] {
        return this.giftshops;
    }

    get CURRENT_GIFTSHOP_ID(): string {
        return this.currentGiftshopId;
    }

    get CURRENT_GIFTSHOP(): Giftshop {
        return this.currentGiftshop;
    }

    get CURRENT_GIFTSHOP_CODES(): GiftshopCode[] {
        return this.currentGiftshopCodes;
    }

    get PRODUCTS(): GiftshopProductViewModel[] {
        return this.products;
    }

    get VIEW_PRODUCT(): GiftshopProductViewModel {
        return this.viewProduct;
    }

    get SALESPERSON_USERS(): GiftshopUser[] {
        return this.salespersonUsers;
    }

    @Action({ commit: 'SET_SETTINGS' })
    public async GET_SETTINGS() {
        return await this.service.getSettings();
    }

    @Mutation
    public SET_SETTINGS(payload: GiftshopSettings) {
        this.settings = payload;
    }

    @Action({ commit: 'SET_ADDRESSES' })
    public async GET_ADDRESSES() {
        return await this.service.getAddresses();
    }

    @Mutation
    public SET_ADDRESSES(payload: AddressInfoDTO[]) {
        this.addresses = payload;
    }

    @Mutation
    public CHANGE_SECTION(payload: GiftshopManagementSection)
    {
        if (payload == this.section)
            return;

        if (payload == GiftshopManagementSection.ASSORTMENT)
            router.push(`/${this.routeHome}/${this.currentGiftshopId}/ass`);
        else if (payload == GiftshopManagementSection.CODES)
            router.push(`/${this.routeHome}/${this.currentGiftshopId}/inv`);
        else if (payload == GiftshopManagementSection.OVERVIEW)
            router.push(`/${this.routeHome}/${this.currentGiftshopId}`);
        else if (payload == GiftshopManagementSection.DASHBOARD)
            router.push(`/${this.routeHome}`);
        else if (payload == GiftshopManagementSection.SUPERVISOR_GIFTSHOPS)
            router.push(`/${this.routeHome}/giftshops`);
        else if (payload == GiftshopManagementSection.SUPERVISOR_PRODUCTS)
            router.push(`/${this.routeHome}/products`);
    }

    @Mutation
    public SET_SECTION(payload: GiftshopManagementSection) {
        this.section = payload;
        this.subsection = GiftshopManagementSubsection.NONE;
    }

    @Mutation
    public SET_SUBSECTION(payload: GiftshopManagementSubsection) {
        this.subsection = payload;
    }

    @Mutation
    public SET_ACTIVE_MODAL(payload: GiftshopManagementModal) {
        this.activeModal = payload;
    }

    @Action({ commit: 'SET_GIFTSHOPS' })
    public async GET_GIFTSHOPS() {
        return await this.service.getGiftshopByUser();
    }

    @Mutation
    public SET_GIFTSHOPS(payload: Giftshop[]) {
        this.giftshops = payload;
    }

    @Action({ commit: 'UPDATE_CURRENT_GIFTSHOP' })
    public async CREATE_GIFTSHOP(name: string) {
        return await this.service.createGiftshop(name);
    }

    @Action({ commit: 'UPDATE_CURRENT_GIFTSHOP' })
    public async PERSIST_GIFTSHOP(payload: GiftshopEditModel) {
        return await this.service.persistGiftshop(payload);
    }

    @Action({ commit: 'UPDATE_CURRENT_GIFTSHOP' })
    public async CLOSE_GIFTSHOP() {
        return await this.service.closeGiftshop(this.currentGiftshopId);
    }

    @Mutation
    public UPDATE_CURRENT_GIFTSHOP(payload: Giftshop) {
        if (this.giftshops.some(g => g.id == payload.id))
        {
            this.giftshops = this.giftshops.map(g => {
                if (payload.id == g.id) return payload;
                return g;
            });
        }
        else
        {
            this.giftshops.unshift(payload);
        }

        this.currentGiftshop = payload;
        this.currentGiftshopId = payload.id;

        if (this.currentGiftshopId != payload.id)
        {
            this.currentGiftshopCodes = [];
            this.section = GiftshopManagementSection.OVERVIEW;
            this.subsection = GiftshopManagementSubsection.NONE;
        }
    }

    @Mutation
    public CHANGE_CURRENT_GIFTSHOP(payload: string)
    {      
        router.push(`/${this.routeHome}/${payload}`);
    }

    @Mutation
    public SET_CURRENT_GIFTSHOP(payload: string) {
        this.currentGiftshopId = payload;
        this.currentGiftshop = this.giftshops.find(g => g.id == payload);
        this.currentGiftshopCodes = [];
        this.section = GiftshopManagementSection.OVERVIEW;
        this.subsection = GiftshopManagementSubsection.NONE;
    }

    @Action({ commit: 'UPDATE_CURRENT_GIFTSHOP' })
    public async UPDATE_DEFAULT_ASSORTMENT(payload: string[]) {
        return await this.service.updateDefaultAssortment(this.currentGiftshopId, payload);
    }
    
    @Action({ commit: 'SET_GIFTSHOP_CODES' })
    public async GET_GIFTSHOP_CODES() {
        return await this.service.getGiftshopCodes(this.currentGiftshopId);
    }

    @Action({ commit: 'SET_GIFTSHOP_CODES' })
    public async CREATE_GIFTSHOP_CODES(qty: number) {
        return await this.service.createGiftshopCodes(this.currentGiftshopId, qty);
    }

    @Action({ commit: 'SET_GIFTSHOP_CODES' })
    public async REMOVE_GIFTSHOP_CODE(code: string) {
        return await this.service.removeGiftshopCode(this.currentGiftshopId, code);
    }

    @Action({ commit: 'SET_GIFTSHOP_CODES' })
    public async SEND_GIFTSHOP_INVITES(invites: GiftshopInvitation[]) {
        return await this.service.sendGiftshopInvites(this.currentGiftshopId, invites);
    }

    @Action({ commit: 'FINALIZE_REQUEST_CARDS' })
    public async REQUEST_CARDS() {
        return this.service.requestCards(this.currentGiftshopId);
    }

    @Mutation
    private FINALIZE_REQUEST_CARDS(cardsRequested: boolean) {
        if (cardsRequested)
            this.currentGiftshop.cardsrequested = true;
    }

    @Mutation
    public SET_GIFTSHOP_CODES(payload: GiftshopCode[]) {
        this.currentGiftshopCodes = payload;
    }

    @Action({ commit: 'UPDATE_PRODUCTS' })
    public async GET_PRODUCTS(ids: string[]) {
        return await this.service.getProductInfo(ids);
    }

    @Mutation
    public UPDATE_PRODUCTS(payload: GiftshopProductViewModel[]) {
        const currentProductIds = this.products.map(prod => prod.id);
        this.products = [...this.products, ...payload.filter(prod => !currentProductIds.includes(prod.id))];
    }

    @Action({ commit: 'SET_VIEW_PRODUCT' })
    public async GET_VIEW_PRODUCT(id: string) {
        return (await this.service.getProductInfo([id]))?.[0];
    }

    @Mutation
    public SET_VIEW_PRODUCT(payload: GiftshopProductViewModel) {
        this.viewProduct = payload;
    }

    @Action
    public async GET_SUPERVISOR_GIFTSHOPS() {
        return await this.service.getSupervisorGiftshops();
    }

    @Action
    public async REMOVE_GIFTSHOP(payload: string) {
        return await this.service.removeGiftshop(payload);
    }

    @Action({ commit: 'UPDATE_SENDING_INVITES' })
    public async CHECK_SENT_INVITES() {
        return await this.service.getGiftshopSentInvitesStatus(this.currentGiftshopId);
    }

    @Mutation
    public UPDATE_SENDING_INVITES(payload: GiftshopCodeInvitationSentStatus[]) {
        const sending = this.currentGiftshopCodes.filter(code => code.invitationstate == GiftshopCodeInvitationState.SENDING).map(code => code.id);
        const sent = sending.filter(code => payload.some(status => status.invitationstate == GiftshopCodeInvitationState.SENT && status.id == code));
        const failed = sending.filter(code => payload.some(status => status.invitationstate == GiftshopCodeInvitationState.FAILED && status.id == code));
        if (sent.length > 0 || failed.length > 0)
        {
            this.currentGiftshopCodes = this.currentGiftshopCodes.map(code => {
                if (sent.includes(code.id))
                    code.invitationstate = GiftshopCodeInvitationState.SENT;
                else if (failed.includes(code.id))
                    code.invitationstate = GiftshopCodeInvitationState.FAILED;
               
                return code;
            });
        }
    }

    @Action({ commit: 'SET_SALESPERSON_USERS' })
    public async GET_SALESPERSON_USERS() {
        return await this.service.getSalespersonUsers();
    }

    @Action({ commit: 'SET_SALESPERSON_USERS' })
    public async CHANGE_SALESPERSON_USER(payload: string) {
        await this.service.changeSalespersonUsers(payload);

        return this.salespersonUsers.map(user => {
            user.active = user.id == payload;
            return user;
        });
    }

    @Mutation
    public SET_SALESPERSON_USERS(payload: GiftshopUser[]) {
        this.salespersonUsers = payload;
    }
}

export default getModule(GiftshopManagementModule);
