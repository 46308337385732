
import { Component, Vue, Watch } from 'vue-property-decorator';
import GiftshopFrontModule from '@/store/modules/GiftshopFrontModule';
import { Giftshop, GiftshopCode, GiftshopCodeState, GiftshopFrontSection, GiftshopState } from '@/models/Giftshop';
import GiftshopFrontProducts from './GiftshopFrontProducts.vue';
import GiftshopFrontLogin from './GiftshopFrontLogin.vue';
import GiftshopFrontCompleted from './GiftshopFrontCompleted.vue';
import GiftshopFrontInfo from './GiftshopFrontInfo.vue';
import GiftshopFrontMessage from './GiftshopFrontMessage.vue';

@Component({
    name: 'GiftshopFront',
    components: {

    },
})
export default class GiftshopFront extends Vue {

    get isLoading(): boolean {
        return GiftshopFrontModule.LOADING;
    }

    get currentSection(): GiftshopFrontSection {
        return GiftshopFrontModule.CURRENT_SECTION;
    }
    get currentView(): any {
        if (this.currentSection == GiftshopFrontSection.INFO)
            return GiftshopFrontInfo;
        else if (this.currentSection == GiftshopFrontSection.MESSAGE)
        return GiftshopFrontMessage;
        else if (this.currentSection == GiftshopFrontSection.PRODUCTS)
            return GiftshopFrontProducts;
        else if (this.currentSection == GiftshopFrontSection.COMPLETED)
            return GiftshopFrontCompleted;

        return GiftshopFrontLogin;
    }

    get styles(): string {
        if (this.giftshop?.theme)
            return `background-color:${this.giftshop.theme.backgroundcolor};color:${this.giftshop.theme.textcolor};`;

        return "";
    }

    get displayHeader(): boolean {
        return this.currentSection == GiftshopFrontSection.PRODUCTS;
    }

    get showGuide() {
        return this.currentSection == GiftshopFrontSection.LOGIN || this.currentSection == GiftshopFrontSection.INFO;
    }

    get logoImage(): string {
        return this.giftshop?.theme?.logoimage ?? "/img/giftshop/firmagavevalg_logo.png";
    }

    get code(): GiftshopCode {
        return GiftshopFrontModule.CODE;
    }
    get codeState(): GiftshopCodeState {
        return GiftshopFrontModule.CODE_STATE;
    }

    get giftshop(): Giftshop {
        return GiftshopFrontModule.GIFTSHOP;
    }
    get giftshopState(): GiftshopState {
        return GiftshopFrontModule.GIFTSHOP_STATE;
    }

    @Watch("currentSection")
    onCurrentSectionChange() {
        if (
            this.currentSection == GiftshopFrontSection.INFO &&
            (this.giftshopState != GiftshopState.OPEN)
        )
        {
            GiftshopFrontModule.SET_CURRENT_SECTION(GiftshopFrontSection.LOGIN);
        }
    }
}
