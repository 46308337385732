
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'checkoutTextInput'
})
export default class CheckoutTextInput extends Vue {
    @Prop({ required: true, default: '' }) private value: string;
    @Prop({ type: String, required: false, default: '' }) private id: string;
    @Prop({ type: String, required: true, default: '' }) private label: string;
    @Prop({ required: false, default: '' }) private rules: string|object;
    @Prop({ type: Boolean, required: false, default: false }) private readonly: boolean;
    @Prop({ type: Boolean, required: false, default: true }) private margin: boolean;

    get placeholder(): string {
        //return `${this.required ? '* ' : ''}${this.label}`;
        return this.label;
    }
}
