import GiftshopSettings from '@/components/giftshop/GiftshopSettings.vue';
import { AddressInfoDTO } from '@/models/Checkout';
import { Giftshop, GiftshopCode, GiftshopCodeEditModel, GiftshopCodeInvitationSentStatus, GiftshopEditModel, GiftshopInvitation, GiftshopProductViewModel, GiftshopViewModel, GiftshopUser } from '@/models/Giftshop';
import axios, { AxiosResponse } from 'axios';

export default class GiftshopService {

    private profileId: string = (window as any).CbxApiContextKey;
    private endpoint: string;
    private config: any = { 
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
        }
    }

    constructor(args: any = {}) {
        if (args.profileId)
            this.profileId = args.profileId;
        this.endpoint = `/contextapi/${this.profileId}/v1/giftshop`;
    }

    public async getSettings(): Promise<GiftshopSettings> {
        try {
            const res: AxiosResponse<GiftshopSettings> = await axios.get(`${this.endpoint}/settings`, this.config);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async getGiftshop(giftshop: string): Promise<Giftshop> {
        try {
            const res: AxiosResponse<Giftshop> = await axios.get(`${this.endpoint}/${giftshop}`, this.config);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async getGiftshopByUser(): Promise<Giftshop[]> {
        try {
            const res: AxiosResponse<Giftshop[]> = await axios.get(`${this.endpoint}/by-user`, this.config);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async getSupervisorGiftshops(): Promise<GiftshopViewModel[]> {
        try {
            const res: AxiosResponse<GiftshopViewModel[]> = await axios.get(`${this.endpoint}/supervisor/giftshops`, this.config);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async createGiftshop(name: string): Promise<Giftshop> {
        try {
            const res: AxiosResponse<Giftshop> = await axios.post(`${this.endpoint}/create`, name, this.config);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async persistGiftshop(editmodel: GiftshopEditModel): Promise<Giftshop> {
        try {
            const res: AxiosResponse<Giftshop> = await axios.post(`${this.endpoint}/persist`, editmodel, this.config);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async removeGiftshop(giftshop: string): Promise<boolean> {
        try {
            await axios.post(`${this.endpoint}/remove`, giftshop, this.config);
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    public async closeGiftshop(giftshop: string): Promise<Giftshop> {
        try {
            const res: AxiosResponse<Giftshop> = await axios.post(`${this.endpoint}/close`, giftshop, this.config);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async updateDefaultAssortment(giftshop: string, assortments: string[]): Promise<Giftshop> {
        try {
            const res: AxiosResponse<Giftshop> = await axios.post(`${this.endpoint}/${giftshop}/default-assortment`, assortments, this.config);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async requestCards(giftshop: string): Promise<boolean> {
        try {
            const res: AxiosResponse<boolean> = await axios.get(`${this.endpoint}/${giftshop}/request-cards`, this.config);
            return res.data;
        } catch (e) {
            console.log(e);
            return false;
        }
    }


    public async getGiftshopCodes(giftshop: string): Promise<GiftshopCode[]> {
        try {
            const res: AxiosResponse<GiftshopCode[]> = await axios.get(`${this.endpoint}/${giftshop}/codes`, this.config);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async getGiftshopCode(code: string): Promise<GiftshopCode> {
        try {
            const res: AxiosResponse<GiftshopCode> = await axios.get(`${this.endpoint}/code/${code}`, this.config);
            return res.data;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    public async persistGiftshopCode(editmodel: GiftshopCodeEditModel): Promise<Giftshop> {
        try {
            const res: AxiosResponse<Giftshop> = await axios.post(`${this.endpoint}/code/persist`, editmodel, this.config);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async removeGiftshopCode(giftshop: string, code: string): Promise<GiftshopCode[]> {
        try {
            const res: AxiosResponse<GiftshopCode[]> = await axios.post(`${this.endpoint}/${giftshop}/codes/remove`, code, this.config);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async createGiftshopCodes(giftshop: string, qty: number): Promise<GiftshopCode[]> {
        try {
            const res: AxiosResponse<GiftshopCode[]> = await axios.post(`${this.endpoint}/${giftshop}/codes/create`, qty, this.config);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async sendGiftshopInvites(giftshop: string, invites: GiftshopInvitation[]): Promise<GiftshopCode[]> {
        try {
            const res: AxiosResponse<GiftshopCode[]> = await axios.post(`${this.endpoint}/${giftshop}/codes/invite`, invites, this.config);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async getGiftshopSentInvitesStatus(giftshop: string): Promise<GiftshopCodeInvitationSentStatus[]> {
        try {
            const res: AxiosResponse<GiftshopCodeInvitationSentStatus[]> = await axios.get(`${this.endpoint}/${giftshop}/codes/sent-status`, this.config);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async getProductInfo(ids: string[]): Promise<GiftshopProductViewModel[]> {
        try {
            const res: AxiosResponse<GiftshopProductViewModel[]> = await axios.post(`${this.endpoint}/productinfo`, ids, this.config);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async getAddresses(): Promise<AddressInfoDTO[]> {
        try {
            const res: AxiosResponse<AddressInfoDTO[]> = await axios.get(`${this.endpoint}/addresses`, this.config);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async getSalespersonUsers(): Promise<GiftshopUser[]> {
        try {
            const res: AxiosResponse<GiftshopUser[]> = await axios.get(`${this.endpoint}/salesperson/users`, this.config);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async changeSalespersonUsers(id: string): Promise<boolean> {
        try {
            const res: AxiosResponse<boolean> = await axios.get(`${this.endpoint}/salesperson/users/change/${id}`, this.config);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }
}
