
import UserModule from '@/store/modules/UserModule';
import { Component, Prop, Vue } from 'vue-property-decorator';
import LoginForm from '../login/LoginForm.vue';
import SignUpForm from '../new-customer/SignUpForm.vue';

@Component({
    name: 'GiftshopButton',
    components: {
        LoginForm,
        SignUpForm,
    },
})
export default class GiftshopButton extends Vue {
    @Prop({ type: Boolean, required: false, default: false }) isHeaderButton: string;
    @Prop({ type: String, required: true }) giftshopText: string;
    @Prop({ type: String, required: true }) giftshopManagementUrl: string;
    @Prop({ type: String, required: true }) giftshopPromotionUrl: string;

    modalOpen = false;
    showSignUpForm = false;

    get translationsReady(): boolean {
        return this.$root.$data.translationsLoaded === true;
    }

    get isSignedIn(): boolean {
        return UserModule.IS_SIGNED_IN;
    }

    handleClick() {
        if (this.isSignedIn)
            window.location.href = this.giftshopManagementUrl;
        else if (this.isHeaderButton)
            window.location.href = this.giftshopPromotionUrl;
        else
            this.modalOpen = true;
    }

    private modalShow() {
        this.modalOpen = true;
    }

    private modalHide() {
        this.modalOpen = false;
        this.showSignUpForm = false;
    }
}
