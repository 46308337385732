import DropdownItem from './DropdownItem';
import { ProductSearchResultProduct } from './Product';

export interface FilterModel {
    rootcategory?: string;
    categories?: string[];
    includedescendantcategories?: boolean;
    fields?: FilterModelField[];
    selections?: FilterRequestSelection[];
    preselections?: FilterRequestSelection[];
    offset?: number;
    count?: number;
    searchphrase?: string;
    totalcount?: number;
    hitcount?: number;
    hits?: FilterModelHit[];
    sortoptions?: SortOption[];
    restrictdoctypes?: string[];
    translations?: { [key: string]: string };
    usefuzzymatch?: boolean;
    usecontentrelationsasselection?: boolean;
    contentrelationnodeid?: string;
}

export interface FilterModelHit {
    id: string;
    docId: number;
    title: string;
    score: number;
    fields: { [key: string]: string[] };
    metadata: HitMetaData;
    type: string;
}

export interface HitMetaData {
    id: string;
    title: string;
    image: string;
    url: string;
    product: ProductSearchResultProduct;
    relatedid?: string;
}

export interface FieldValueOption {
    value: string;
    label?: string;
    count: number;
}

export interface FilterRequestSelection {
    path: string;
    label?: string;
    values?: string[];
    minvalue?: number | null;
    maxvalue?: number | null;
}

export interface FilterModelField {
    path: string;
    type: CustomIndexConfigFieldType;
    label?: string;
    values?: FieldValueOption[];
    treevalues?: any;
    minvalue?: number | null;
    maxvalue?: number | null;
    minmaxvatrate?: number | null;
    maxcount?: number | null;
}

export interface SortOption {
    field: string;
    reverse: boolean;
    type: SortOptionType;
}

export enum SortOptionType {
    SCORE = 0,
    DOC = 1,
    STRING = 3,
    INT = 4,
    FLOAT = 5,
    LONG = 6,
    DOUBLE = 7,
    SHORT = 8,
    CUSTOM = 9,
    BYTE = 10,
    STRING_VAL = 11,
}

export interface ProductListOptions {
    sortoptions: DropdownItem[];
    selectedsortoption: string;
    pagelimitoptions: number[];
    selectedpagelimitoption: number;
}

export interface IndexInfoModel {
    indexconfig?: IndexInfoConfig;
    lastbuild?: Date;
    lastconsolidate?: Date;
    maxtimestamp?: Date;
    documentinfo?: IndexInfoDocument[];
    customindexconfig?: CustomIndexConfig;
}

export interface IndexInfoConfig {
    fields: string[];
    languages: string[];
}

export interface IndexInfoDocument {
    value: string;
    count: number;
}

export interface CustomIndexConfig {
    fields: CustomIndexConfigField[];
    lastupdated: Date;
}

export interface CustomIndexConfigField {
    field: string;
    type: CustomIndexConfigFieldType;
}

export enum CustomIndexConfigFieldType {
    OTHER = 'OTHER', // -1,
    STRING = 'STRING', // 0,
    NUMBER = 'NUMBER', // 1,
    DATE = 'DATE', // 2,
    LIST = 'LIST', // 3
    TREE = 'TREE', // 4
    BOOLEAN = 'BOOLEAN', // 5
    STRING_LOCALIZABLE = 'STRING_LOCALIZABLE', // 6
}
