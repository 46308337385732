
import { Component, Vue } from 'vue-property-decorator';
import GiftshopManagementModule from '@/store/modules/GiftshopManagementModule';
import { GiftshopConfig, GiftshopManagementSection } from '@/models/Giftshop';

@Component({
    name: 'GiftshopManagementHelp',
    components: {
    },
})
export default class GiftshopManagementHelp extends Vue {

    expanded: boolean = false;

    get translationsReady(): boolean {
        return this.$root.$data.translationsLoaded === true;
    }

    get config(): GiftshopConfig {
        return GiftshopManagementModule.SETTINGS.defaultconfiguration
    }

    get section(): GiftshopManagementSection {
        return GiftshopManagementModule.SECTION;
    }
    get isOverview(): boolean {
        return this.section == GiftshopManagementSection.OVERVIEW;
    }
    get isAssortment(): boolean {
        return this.section == GiftshopManagementSection.ASSORTMENT;
    }
    get isCodes(): boolean {
        return this.section == GiftshopManagementSection.CODES;
    }

    get helpText(): string {
        if (this.isOverview)
            return this.config.overviewhelptext;
        else if (this.isAssortment)
            return this.config.assortmenthelptext;
        else if (this.isCodes)
            return this.config.codeshelptext;

        return "";
    }

    toggleContent() {
        this.expanded = !this.expanded;
    }
}
