
import { Component, Vue } from 'vue-property-decorator';
import GiftshopFrontModule from '@/store/modules/GiftshopFrontModule';
import { GiftshopCodeState, GiftshopFrontSection, GiftshopState } from '@/models/Giftshop';

@Component({
    name: 'GiftshopFrontLogin',
    components: {

    },
})
export default class GiftshopFrontLogin extends Vue {
    code: string = null;

    get isLoading(): boolean {
        return GiftshopFrontModule.LOADING;
    }

    get codeState(): GiftshopCodeState {
        return GiftshopFrontModule.CODE_STATE;
    }
    get giftshopState(): GiftshopState {
        return GiftshopFrontModule.GIFTSHOP_STATE;
    }
    get errorMesage(): string {
        if (this.giftshopState == GiftshopState.UNKNOWN) return "Ukendt gaveshop";     
        if (this.giftshopState == GiftshopState.CLOSED) return "Gaveshoppen er afsluttet";
        if (this.giftshopState == GiftshopState.AWAITING) return "Gaveshoppen er ikke åben endnu";
        if (this.codeState == GiftshopCodeState.UNKNOWN) return "Ukendt kode";
        if (this.codeState == GiftshopCodeState.USED) return "Kode allerede brugt";

        return null;
    }

    get hideForm(): boolean {
        return this.giftshopState != null && this.giftshopState != GiftshopState.OPEN; 
    }

    async handleContinue() {
        GiftshopFrontModule.SET_LOADING(true);
        
        const code = await GiftshopFrontModule.GET_CODE(this.code);
        if (code)
        {
            await GiftshopFrontModule.GET_GIFTSHOP(code.shopid);
            
            GiftshopFrontModule.SET_CURRENT_SECTION(GiftshopFrontSection.INFO);
        }

        GiftshopFrontModule.SET_LOADING(false);
    }
}
