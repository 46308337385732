import axios, { AxiosResponse } from 'axios';
import { Customer, CustomerProfile } from '@/models/Customer';
import { OptInRequest, OptInResponse, SignUpRequest, SignUpResponse, SignUpResponseError } from '@/models/SignUp';
import { CbxVariablesModule } from '@/store/modules/CbxVariablesModule';

export default class CustomerService {

    private profileId: string = (window as any).CbxApiContextKey;
    private endpoint: string;

    constructor(args: any = {}) {
        if (args.profileId)
            this.profileId = args.profileId;
        this.endpoint = `/contextapi/${this.profileId}/v1/customer`;
    }

    public async getCustomer(): Promise<Customer> {
        try {
            const res: AxiosResponse<any> = await axios.get(`${this.endpoint}`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async search(query: string, limit: number = 15, offset: number = 0): Promise<Customer[]> {
        try {
            const res: AxiosResponse<any> = await axios.get(`${this.endpoint}/search/?query=${query}&limit=${limit}&offset=${offset}`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }    

    public async changeCustomer(selectedcustomer: string): Promise<Customer> {
        try {
            const res: AxiosResponse<any> = await axios.post(`${this.endpoint}/change/?selectedcustomer=${selectedcustomer}`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }      

    public async changeCustomerProfile(customerprofile: CustomerProfile): Promise<any> {
        try {
            const res: AxiosResponse<any> = await axios.get(`${this.endpoint}/changeprofile/?customerprofile=${customerprofile}`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async signUp(payload: SignUpRequest): Promise<SignUpResponse> {
        try {
            const res: AxiosResponse<SignUpResponse> = await axios.post(`${this.endpoint}/signup`, payload);
            return res.data;
        } catch (e) {
            return {
                signupcompleted: false,
                error: SignUpResponseError.Critical,
            }
        }
    }

    public async finalizeOptIn(payload: OptInRequest): Promise<OptInResponse> {
        try {
            const fd = new FormData();
            fd.append('actionid', 'optin');
            fd.append('output', 'json');
            fd.append('newpassword', encodeURIComponent(payload.newpassword));
            fd.append('confirmnewpassword', encodeURIComponent(payload.confirmnewpassword));
            const response: AxiosResponse = await axios({
                url: `${ CbxVariablesModule.VARIABLES.loginpage }?json=true&token=${ payload.token }`,
                method: 'POST',
                data: fd,
                headers: {'Content-Type': 'multipart/form-data' },
            });
            return response.data;
        } catch (e) {
            return {
                errortype: 1,
                optinerror: true,
            };
        }
    }

}
