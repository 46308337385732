const UIkit = window.UIkit;

export default function () {
    if (document.getElementById("brand-search-input"))
    {
        UIkit.util.on(document, 'keyup', '#brand-search-input', function (e) {
            const resultEle = document.getElementById("brand-search-result");
            const val = e.current.value.toLowerCase();

            (document.querySelectorAll("#brand-search-result [data-brand]") ?? []).forEach(ele => {
                ele.remove();
            });

            if (val.length > 0)
            {
                document.querySelector(".brand-list").classList.add("uk-hidden");
                document.querySelectorAll(".brand-list [data-brand]").forEach(ele => {
                    if (ele.dataset.brand.toLowerCase().includes(val))
                        resultEle.appendChild(ele.cloneNode(true));
                });
            }
            else
            {
                document.querySelector(".brand-list").classList.remove("uk-hidden");
            }
        });
    }
}