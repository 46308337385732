
import { Component, Vue, Watch } from 'vue-property-decorator';
import GiftshopManagementModule from '@/store/modules/GiftshopManagementModule';
import { Giftshop, GiftshopManagementModal, GiftshopManagementSection, GiftshopUser } from '@/models/Giftshop';
import CheckoutTextInput from '../checkout/CheckoutTextInput.vue';
import UserModule from '@/store/modules/UserModule';
import GiftshopManagementShortcuts from './GiftshopManagementShortcuts.vue';
import GiftshopManagementBreadcrumb from './GiftshopManagementBreadcrumb.vue';
import GiftshopManagementHelp from './GiftshopManagementHelp.vue';

@Component({
    name: 'GiftshopManagement',
    components: {
        GiftshopManagementBreadcrumb,
        GiftshopManagementShortcuts,
        CheckoutTextInput,
        GiftshopManagementHelp,
    },
})
export default class GiftshopManagement extends Vue {

    modals: any = {
        create: false,
    };
    createName: string = "";

    get translationsReady(): boolean {
        return this.$root.$data.translationsLoaded === true;
    }

    get isSupervisor(): boolean {
        return UserModule.USER_ROLE == "Supervisor";
    }
    get isSalesperson(): boolean {
        return UserModule.USER_ROLE == "Salesperson";
    }

    get salespersonUsers(): GiftshopUser[] {
        return GiftshopManagementModule.SALESPERSON_USERS;
    }
    get activeSalespersonUser(): string {
        return this.salespersonUsers.find(user => user.active)?.id;
    }
    set activeSalespersonUser(input: string) {
        GiftshopManagementModule.CHANGE_SALESPERSON_USER(input);
    }

    get section(): GiftshopManagementSection {
        return GiftshopManagementModule.SECTION;
    }
    get showOverview(): boolean {
        return this.section == GiftshopManagementSection.OVERVIEW;
    }
    get showAssortment(): boolean {
        return this.section == GiftshopManagementSection.ASSORTMENT;
    }
    get showCodes(): boolean {
        return this.section == GiftshopManagementSection.CODES;
    }

    get activeModal(): GiftshopManagementModal {
        return GiftshopManagementModule.ACTIVE_MODAL;
    }

    get giftshops(): Giftshop[] {
        return GiftshopManagementModule.GIFTSHOPS;
    }

    get currentGiftshopId(): string {
        return GiftshopManagementModule.CURRENT_GIFTSHOP_ID;
    }
    set currentGiftshopId(input: string) {
        GiftshopManagementModule.CHANGE_CURRENT_GIFTSHOP(input);
    }
    get currentGiftshop(): Giftshop {
        return GiftshopManagementModule.CURRENT_GIFTSHOP;
    }

    changeSection(input: string) {
        let section: GiftshopManagementSection = null;
        if (input == "dashboard")
            section = GiftshopManagementSection.DASHBOARD;
        else if (input == "overview")
            section = GiftshopManagementSection.OVERVIEW;
        else if (input == "assortment")
            section = GiftshopManagementSection.ASSORTMENT;
        else if (input == "codes")
            section = GiftshopManagementSection.CODES;
        else if (input == "supervisor-giftshops")
            section = GiftshopManagementSection.SUPERVISOR_GIFTSHOPS;
        else if (input == "supervisor-products")
            section = GiftshopManagementSection.SUPERVISOR_PRODUCTS;

        if (section != null)
            GiftshopManagementModule.CHANGE_SECTION(section);
    }

    async handleCreate() {
        const res = await GiftshopManagementModule.CREATE_GIFTSHOP(this.createName);
        await GiftshopManagementModule.CHANGE_CURRENT_GIFTSHOP(res.id);
        this.modalHide('create');
        this.createName = "";
    }

    modalShow(modal: string) {
        this.modals[modal] = true;
    }

    modalHide(modal: string) {
        this.modals[modal] = false;
        GiftshopManagementModule.SET_ACTIVE_MODAL(GiftshopManagementModal.NONE);
    }

    private async created() {
        if (this.isSalesperson)
            await GiftshopManagementModule.GET_SALESPERSON_USERS();
    }

    @Watch("activeSalespersonUser")
    private async onActiveSalespersonUserChange(changedTo: any, changedFrom: any) {
        if (changedFrom && changedTo)
        {
            location.hash = '';
            location.reload();
        }
    }

    @Watch("currentGiftshopId")
    private onCurrentGiftshopIdChange() {
        const currentProductIds = GiftshopManagementModule.PRODUCTS.map(prod => prod.id);
        if (this.currentGiftshop?.defaultproducts.some(prod => !currentProductIds.includes(prod)))
            GiftshopManagementModule.GET_PRODUCTS(this.currentGiftshop.defaultproducts.filter(prod => !currentProductIds.includes(prod)));
    }

    @Watch("activeModal")
    private onActiveModalChange() {
        if (this.activeModal == GiftshopManagementModal.CREATE_GIFTSHOP)
            this.modalShow("create");
    }
}
