
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Debounce } from 'vue-debounce-decorator';
import SuggestService from '../../services/SuggestService';
import { SuggestedAddress } from '@/models/Suggest';
import { mixin as clickaway } from 'vue-clickaway';
const suggestService: SuggestService = new SuggestService();

@Component({
    name: 'SignUpFormAddressInputSuggest',
    mixins: [clickaway],
})
export default class SignUpFormAddressInputSuggest extends Vue {
    @Prop({ required: true }) private value: string;
    @Prop({ type: String, required: false, default: '' }) private id: string;
    @Prop({ type: String, required: true, default: '' }) private label: string;
    @Prop({ required: false, default: '' }) private rules: string|object;
    @Prop({ type: Boolean, required: false, default: true }) private margin: boolean;

    private suggestions: SuggestedAddress[] = [];

    get placeholder(): string {
        return `${this.label}${String(this.rules).split("|").includes("required") ? " *" : ""}`;
    }

    get service(): string {
        return "dawa";
    }

    get autocomplete(): string {
        return this.service ? 'disabled' : 'on';
    }

    private setFocus(index: number) {
        if (this.suggestions?.length)
        {
            let focusTarget: any;
            if (index < 0 || index > this.suggestions.length-1) {
                focusTarget = this.$refs.addressInput;
            } else {
                focusTarget = (this.$refs[`address-suggestion-${index}`] as any)[0];
            }
            this.$nextTick(() => focusTarget.focus());
        }
    }

    private useSuggestion(suggest: SuggestedAddress) {

        if (suggest.address1.trim().length > 0) {
            this.$emit("change", {
                address: suggest.address1,
                zipcode: suggest.zip,
                city: suggest.city
            });
        } else {
            this.$emit("change", {
                suggestion: suggest.suggestion,
            });
        }
        
        this.suggestions = [];
        (this.$refs.addressInput as any).focus();
    }

    @Debounce(250)
    private async suggest() {
        const service = this.service;

        if (service != null && this.value?.length >= 3)
            this.suggestions = await suggestService.suggestAddress(service, this.value)
        else
            this.suggestions = [];
    }

    private handleClickAway(e: any) {
        if (!this.$el.contains(e.relatedTarget)) {
            this.suggestions = [];
        }
    }
}
