
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
    name: "SignUpFormTextInput"
})
export default class SignUpFormTextInput extends Vue {
    @Prop({ required: true, default: '' }) private value: string;
    @Prop({ type: String, required: false, default: "text" }) private type: string;
    @Prop({ type: String, required: false, default: "" }) private id: string;
    @Prop({ type: String, required: true, default: "" }) private label: string;
    @Prop({ type: String, required: false, default: null }) private prefix: string;
    @Prop({ required: false, default: '' }) private rules: string|object;
    @Prop({ type: Boolean, required: false, default: true }) private margin: boolean;


    get placeholder(): string {
        return `${this.label}${String(this.rules).split("|").includes("required") ? " *" : ""}`;
    }
}
