export interface SignUpRequest {
    companyname?: string;
    address?: string;
    zipcode?: string;
    city?: string;
    country?: string;
    name: string;
    email: string;
    phone?: string;
    vatno?: string;
    eanno?: string;
}

export interface SignUpResponse {
    signupcompleted: boolean;
    error: SignUpResponseError;
}

export enum SignUpResponseError {
    None,
    MissingDetails,
    CustomerExists,
    UserExists,
    Critical
}

export interface OptInRequest {
    newpassword: string;
    confirmnewpassword: string;
    token?: string;
}

export interface OptInResponse {
    errortype?: number;
    optinerror?: boolean;
    optincompleted?: boolean;
}