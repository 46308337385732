import { SuggestedAddress } from '@/models/Suggest';
import axios, { AxiosResponse } from 'axios';

export default class SuggestService {

    //constructor(args: any = {}) {
    //}

    public async suggestAddress(service: string, query: string): Promise<SuggestedAddress[]> {
        if (service == '' || query == '') return [];

        try {
            const res: AxiosResponse<SuggestedAddress[]> = await axios.get(`/api/thirdparty/${service}/address?query=${query}`);
            return res.data;
        } catch (e) {
            console.log(e);

            return [];
        }
    }
}
