// Transpiler for older browsers
import 'core-js/stable';

// @ts-ignore
import UiKitExtensions from '@/uikit-extensions';
UiKitExtensions();

import Vue from 'vue'
import 'animate.css';


import '@/plugins';
import '@/filters';

import VueScreen from 'vue-screen';
Vue.use(VueScreen, {
    mobile: '0px',
    desktop: '960px',
    breakpointsOrder: ['mobile', 'desktop'],
});

import PortalVue from 'portal-vue';
Vue.use(PortalVue);

import store from '@/store';
import CartModule from '@/store/modules/CartModule';
import ProductDetailsModule from '@/store/modules/ProductDetailsModule';

// @ts-ignore
import * as GmapVue from 'gmap-vue';
if ((window as any).CbxGmapsAPIKey?.length) {
    // @ts-ignore
    Vue.component('GmapCluster', () => import('gmap-vue/dist/components/cluster'));
    Vue.use(GmapVue, {
        load: {
            key: (window as any).CbxGmapsAPIKey,
        },
        installComponents: true
    });
}

// @ts-ignore
import { VueResponsiveComponents } from 'vue-responsive-components';
Vue.use(VueResponsiveComponents);

import VuePapaParse from "vue-papa-parse";
Vue.use(VuePapaParse);

Vue.config.productionTip = false;


import { CbxVariablesModule } from './store/modules/CbxVariablesModule';
import EventTrackingModule from './store/modules/EventTrackingModule';

if ((window as any).CbxCurrentProductId.length)
{
    ProductDetailsModule.GET_PRODUCT((window as any).CbxCurrentProductId);
    if (CbxVariablesModule.VARIABLES.enableEventTracking)
        EventTrackingModule.TRACK_VIEW({ type: 'product', id: CbxVariablesModule.VARIABLES.currentProductId });
}
if ((window as any).CbxSelectedVariantId.length)
    ProductDetailsModule.SET_SELECTED_VARIANT_ID((window as any).CbxSelectedVariantId);

// localisation / translations
import VueI18n from 'vue-i18n';
import { getI18n, getMissingHandler, loadLocale, localeToLanguage } from './utils/TranslationsUtils';
Vue.use(VueI18n);
const locale = (window as any).CbxCulture ?? 'en-GB';
const i18n = getI18n(locale);


import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { extend } from 'vee-validate';
import { isValidEan, setupValidationRules } from './utils/ValidationUtils';
import { localize } from 'vee-validate';
setupValidationRules();


Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.component('ProgressBar', () => import('@/components/progress-bar/ProgressBar.vue'));

import router from "@/router";
if ((window as any).CbxIsGiftshopPage)
{
    (window as any).giftshopapp = new Vue({
        i18n,
        store,
        router,
        el: '#cbx-vue-giftshop-app',
        data: {
            translationsLoaded: false,
            cartLoaded: false,
        },
        beforeCreate: async function () {
            try {
                const lang = localeToLanguage(locale);
                const validationTranslations = await import(/* webpackMode: "eager" */ `vee-validate/dist/locale/${lang}.json`);
                localize(lang, validationTranslations);
                extend('true',  value => {
                    if (value === true) return true;
                    return validationTranslations.messages.required;
                });
                extend('ean', value => {
                    if (isValidEan(value)) return true;
                    return validationTranslations.messages.regex;
                });
            } catch (e) {
                console.log('failed to bootstrap validation translations', e);
            }
            await loadLocale(locale, i18n);
            i18n.missing = getMissingHandler(locale);
            this.$data.translationsLoaded = true;

            const cartPromises: any[] = [
                CartModule.GET_CART(),
                CartModule.GET_CART_CURRENT_DELIVERY(),
            ];
            if ((window as any).CbxEnableFreeGifts) cartPromises.push(CartModule.GET_CART_CURRENT_FREE_GIFT());
            await Promise.allSettled(cartPromises);
            this.$data.cartLoaded = true;
        },
        methods: {
            reInitialize: function() {
                console.log('reInitialize');
                this.$forceUpdate();
            }
        }
    });
}
else
{
(window as any).app = new Vue({
    i18n,
    store,
    el: '#cbx-vue-app',
    data: {
        translationsLoaded: false,
        cartLoaded: false,
    },
    beforeCreate: async function () {
        try {
            const lang = localeToLanguage(locale);
            const validationTranslations = await import(/* webpackMode: "eager" */ `vee-validate/dist/locale/${lang}.json`);
            localize(lang, validationTranslations);
            extend('true',  value => {
                if (value === true) return true;
                return validationTranslations.messages.required;
            });
            extend('ean', value => {
                if (isValidEan(value)) return true;
                return validationTranslations.messages.regex;
            });
        } catch (e) {
            console.log('failed to bootstrap validation translations', e);
        }
        await loadLocale(locale, i18n);
        i18n.missing = getMissingHandler(locale);
        this.$data.translationsLoaded = true;

        const cartPromises: any[] = [
            CartModule.GET_CART(),
            CartModule.GET_CART_CURRENT_DELIVERY(),
        ];
        if ((window as any).CbxEnableFreeGifts) cartPromises.push(CartModule.GET_CART_CURRENT_FREE_GIFT());
        await Promise.allSettled(cartPromises);
        this.$data.cartLoaded = true;
    },
    methods: {
        reInitialize: function() {
            console.log('reInitialize');
            this.$forceUpdate();
        }
    }
});
}