import { Customer } from "./Customer";

export interface Giftshop {
    id: string;
    name: string;
    customerid: string;
    userid: string;
    startdate: Date;
    enddate: Date;
    delivery: GiftshopDelivery;
    wrappingrequested: boolean;
    homedeliveryrequested: boolean;
    requireddefaultproducts: number;
    defaultproducts: string[];
    defaultproductsinfo: GiftshopProductInfo[];
    products: string[];
    theme: GiftshopTheme | null;
    message: string;
    emailmessage: string;
    cardsrequested: boolean;
    status: GiftshopStatus;
}

export class GiftshopEditModel implements Giftshop {
    constructor({
        id,
        name,
        customerid,
        userid,
        startdate,
        enddate,
        delivery,
        wrappingrequested,
        homedeliveryrequested,
        requireddefaultproducts,
        defaultproducts,
        defaultproductsinfo,
        products,
        theme,
        message,
        emailmessage,
        cardsrequested,
        status,
    }: Giftshop) {
        Object.assign(this, {
            id,
            name,
            customerid,
            userid,
            startdate,
            enddate,
            delivery,
            wrappingrequested,
            homedeliveryrequested,
            requireddefaultproducts,
            defaultproducts,
            defaultproductsinfo,
            products,
            theme,
            message,
            emailmessage,
            cardsrequested,
            status: status ?? GiftshopStatus.OPEN,
        });
    }

    id: string;
    name: string;
    customerid: string;
    userid: string;
    startdate: Date;
    enddate: Date;
    delivery: GiftshopDelivery;
    wrappingrequested: boolean;
    homedeliveryrequested: boolean;
    requireddefaultproducts: number
    defaultproducts: string[];
    defaultproductsinfo: GiftshopProductInfo[];
    products: string[];
    theme: GiftshopTheme | null;
    message: string;
    emailmessage: string;
    cardsrequested: boolean;
    status: GiftshopStatus;
}

export interface GiftshopTheme {
    backgroundcolor: string;
    textcolor: string;
    logoimage: string;
}

export interface GiftshopDelivery {
    name?: string;
    address?: string;
    zipcode?: string;
    city?: string;
    date?: Date | null;
}

export interface GiftshopProductInfo {
    id: string;
    name: string;
    image: string;
}

export enum GiftshopStatus {
    OPEN = 0,
    CLOSED = 1,
}

export interface GiftshopViewModel {
    id: string;
    giftshop: Giftshop;
    customer: Customer;
    displaydeliverydate: string;
    displayenddate: string;
    codes: GiftshopCode[];
}

export enum GiftshopManagementSection {
    DASHBOARD = 0,
    OVERVIEW = 1,
    ASSORTMENT = 2,
    CODES = 3,
    SUPERVISOR_GIFTSHOPS = 4,
    SUPERVISOR_PRODUCTS = 5,
}

export enum GiftshopManagementSubsection {
    NONE = 0,
    ASSORTMENT_PRODUCTS = 1,
    ASSORTMENT_DEFAULT_PRODUCTS = 2
}

export enum GiftshopManagementModal {
    NONE = 0,
    CREATE_GIFTSHOP = 1,
    EDIT_DATES = 2,
    EDIT_DELIVERY_DATE = 3,
    EDIT_DELIVERY_ADDRESS = 4,
    EDIT_THEME = 5,
    EDIT_MESSAGE = 6,
    ADD_CODES = 7,
    SEND_CODES = 8,
}

export interface GiftshopSettings {
    defaultconfiguration: GiftshopConfig;
    assortments: GiftshopAssortment[];
    deliveryoptions: GiftshopDeliveryOption[];
    defaulttheme: GiftshopTheme;
}

export interface GiftshopConfig {
    lastday: Date;
    latestdelivery: Date;
    defaultlifespan: number;
    overviewhelptext: string;
    assortmenthelptext: string;
    codeshelptext: string;
}

export interface GiftshopAssortment {
    id: string;
    name: string;
    description: string;
    products: string[];
    previewimages: string[];
}

export interface GiftshopDeliveryOption {
    id: string;
    icon: string;
    name: string;
    description: string;
}

export interface GiftshopCode {
    id: string;
    shopid: string;
    name: string;
    email: string;
    products: string[];
    productsinfo: GiftshopProductInfo[];
    cardrequested: boolean;
    invitationstate: GiftshopCodeInvitationState;
    createdate: Date;
    lastupdate: Date;
}

export class GiftshopCodeEditModel implements GiftshopCode {
    constructor({
        id,
        shopid,
        name,
        email,
        products,
        productsinfo,
        cardrequested,
        invitationstate,
        createdate,
        lastupdate,
    }: GiftshopCode) {
        Object.assign(this, {
            id,
            shopid,
            name,
            email,
            products,
            productsinfo,
            cardrequested,
            invitationstate,
            createdate,
            lastupdate,
        });
    }

    id: string;
    shopid: string;
    name: string;
    email: string;
    products: string[];
    productsinfo: GiftshopProductInfo[];
    cardrequested: boolean;
    invitationstate: GiftshopCodeInvitationState;
    createdate: Date;
    lastupdate: Date;
}

export enum GiftshopState {
    UNKNOWN = 0,
    OPEN = 1,
    AWAITING = 2,
    CLOSED = 3,
}

export enum GiftshopCodeState {
    UNKNOWN = 0,
    UNUSED = 1,
    USED = 2,
}

export enum GiftshopCodeInvitationState {
    MANUAL = 0,
    SENDING = 1,
    SENT = 2,
    FAILED = 3,
}

export interface GiftshopCodeInvitationSentStatus {
    id: string;
    invitationstate: GiftshopCodeInvitationState;
}

export interface GiftshopInvitation {
    id?: string;
    name: string;
    email: string;
}

export interface GiftshopProductViewModel {
    id: string;
    name: string;
    shortdescription: string;
    longdescription: string;
    image: string;
    images: string[];
    badges: string[];
    icons: string[];
    variants: any[];
}

export enum GiftshopFrontSection {
    LOGIN = 0,
    INFO = 1,
    MESSAGE = 2,
    PRODUCTS = 3,
    COMPLETED = 4,
}

export interface GiftshopOrderedProduct {
    id: string;
    name: string;
    quantityClosed: number;
    quantityTotal: number;
    giftshops: GiftshopOrderedProductGiftshop[];
}

export interface GiftshopOrderedProductGiftshop {
    id: string;
    name: string;
    status: GiftshopStatus;
    quantity: number;
    enddate: string;
    customer: Customer;
}

export interface GiftshopUser {
    id: string;
    name: string;
    email: string;
    active: boolean;
}