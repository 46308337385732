
import { Component, Vue } from 'vue-property-decorator';
import GiftshopFrontModule from '@/store/modules/GiftshopFrontModule';
import { GiftshopCode, GiftshopFrontSection } from '@/models/Giftshop';

@Component({
    name: 'GiftshopFrontMessage',
    components: {

    },
})
export default class GiftshopFrontMessage extends Vue {

    get code(): GiftshopCode {
        return GiftshopFrontModule.CODE;
    }

    get message(): string {
        if (GiftshopFrontModule.GIFTSHOP.message?.length > 0)
            return GiftshopFrontModule.GIFTSHOP.message;

        return `
            Hej ${this.code.name}<br />
            På næste side skal du vælge din gave.<br />
            God fornøjelse - Firmagavevalg.dk
        `;
    }

    async handleContinue() {
        GiftshopFrontModule.SET_CURRENT_SECTION(GiftshopFrontSection.PRODUCTS);
    }
}
